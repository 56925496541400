import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Message from './Message';
import server from './ServerAPI'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment'


export default function MainPage() {
	
	const [id] = useState(useParams().id);
	const [messages, setMessages] = useState([]);
	const [page, setPage] = useState(1);
	const [messagesCount, setMessagesCount] = useState(1);
	const [openMessage, setOpenMessage] = useState();
	const [diaryPosts, setDiaryPosts] = useState([]);
	const [openDiary, setOpenDiary] = useState([]);

	useEffect(()=> {
		async function getMessages(query) {
			const res = await server.Get(query+"_sort=id&_order=desc")
			
			if (res.headers["x-total-count"])
				setMessagesCount(res.headers["x-total-count"])
			
			if (!Array.isArray(res.data))
				res.data = [res.data];
			
			return res.data;
			
		}
		if (id && id.indexOf("@") > -1) {
			getMessages("posts/?"+id + "&_page="+page+"&").then(res => setMessages(res));
			
			if (localStorage.getItem("key"))
				getMessages("diary/?"+id + "&_page="+page+"&").then(res => setOpenDiary(res));
		}
		else if (!id && !localStorage.getItem("key"))
			getMessages("/posts/?@projektit&visible=true&_page="+page+"&").then(res => setMessages(res));
		else {
			if (!id && diaryPosts.length === 0 && localStorage.getItem("key"))
				getMessages("diary/?", true).then(res => setDiaryPosts(res));
			const txt_id = id? "/"+id : ""
			getMessages("posts"+txt_id+"?_page="+page+"&").then(res => setMessages(res));
		}
	}, [id, page, diaryPosts])

	const nextPage = _ => {
		setOpenMessage(null);
		setPage(page +1)
	}
	const prevPage = _ => {
		setOpenMessage(null);
		setPage(page -1)
	}

	const clickMessage = (e, i) => {
		if (!id && openMessage !== i) {
			e.stopPropagation();
		//if (e.target.classList.contains("collapsed")) {
			setOpenMessage(i);
		}
	}

	useEffect(() => {
		if (openMessage)
			window.location.hash = "post"+openMessage
	}, [openMessage]);

	const [date, setDate] = useState(new Date())
	const changeDate = (e => {
		setDate(e)
	})
	useEffect(() => {
		if (diaryPosts)
			setOpenDiary(diaryPosts
				.filter(post => post.date === moment(date).format("YYYY-MM-DD")))
	}, [diaryPosts, date])

	function listPosts() {
		if (id && id.indexOf("@") > -1)
			return (openDiary.concat(messages)
					.sort((a,b) => b.created - a.created))
		else
			return messages;
	}
	function getEntryDays({date, view}) {
		if (diaryPosts.find(x => x.date === moment(date).format("YYYY-MM-DD")))
			return "activeday";
	}
	return (
		<div className="App">
			{ localStorage.getItem("key") ? <>
				<div className="topbar"><a className="toolbarElement" href="/edit">Uusi kirjaus</a></div>
				{!id? <>
					<div className="calendarContainer">
						<Calendar onChange={changeDate} value={date} tileClassName={getEntryDays} />
					</div>
					{openDiary.length && !id ? <a className="none" href={"/day/"+openDiary[0].id}>
						<Message message={openDiary[0]} /></a> : null}
				</>:null}
			</> :null}
			{listPosts().map(message =>
				<Message key={message.id}
					onClick={e => clickMessage(e, message.id)}
					message={message}
					collapsed={id || openMessage === message.id? false:true} />
			)}
			<div className="pagination">
				{ page > 1?
					<button className="link-button" onClick={prevPage}>Edellinen sivu</button> : null}
				{ page * 10 < messagesCount ?
					<button className="link-button" onClick={nextPage}>Seuraava sivu</button> : null}
			</div>
		</div>
	)
}
