import axios from 'axios'
import { server_url } from './config'
import imageCompression from 'browser-image-compression';
import moment from 'moment'

const connection = axios.create({
	baseURL: server_url,
	headers: {"key": localStorage.getItem("key")}
})

const Get = async (what) => {
	return (await connection.get(what))
}
const Post = async (url, data) => {
	return await connection({method: 'post', url, data})
}
const Delete = async (url) => {
	return await connection({method: 'delete', url})
}
const Put = async (url, data) => {
	return await connection({method: 'put', url, data})
}
const UploadImage = async (file, name) => {
	function correctFileName() {
		name = decodeURIComponent(name)
		if (name.length > 100)
			name = name.substring(0, 100)

		const file_extension = "."+file.type.substring(file.type.length - 3);
		name = name.substring(0, name.length - 4) + file_extension;
		
		return name;
	}
	const compressedFile = await imageCompression(file, {
		maxSizeMB: 2,
		maxWidthOrHeight: 2560
	});
	
	let data = new FormData();
	data.append(correctFileName(), compressedFile)

	return (await Post("/upload", data)).headers.filename;
}


const SendPost = async (url, data, oldPost) => {
	if (oldPost.time) {
		const res = (await Get(url)).data;
		if (res.time > oldPost.time || res.body.localeCompare(oldPost.body) !== 0)
			throw new Error("Tallennus epäonnistui. Viesti on päivittynyt sivun lataamisen jälkeen!");
	}

	data.created = oldPost.created;
	data.time = Date.now();
	
	if (url.search("diary") !== -1) {
		data.date = oldPost.time? oldPost.date : moment().format("YYYY-MM-DD");
		delete data.title;
		delete data.visible;
	}

	if (oldPost.time)
		return await Put(url, data)
	else 
		return await Post(url,
			{ ...data, created: data.time })

}
export default {
	Get,
	Delete,
	Post,
	Put,
	SendPost,
	UploadImage	
}
/*
const GetFile = async (what) => {
	return (
		await connection.get(what, {responseType: 'arraybuffer'})
	).data
}
*/

/*
const imageData = {};
const requestedImages = [];

export const loadImage = async (name, callback) => {
	if (requestedImages.includes(name))
		return imageData;
	else {
		requestedImages.push(name);

		let res = await GetFile("image/"+name);
		const idata = Buffer.from(res, 'binary').toString('base64')
		imageData[name] = idata;
		return imageData;
	}
}

*/