import React, { useState, useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";
import './App.css';
import server from './ServerAPI'
import moment from 'moment'
import ReactDropzone from 'react-dropzone';
import Message from './Message';
import EditArea from './EditArea';
//import * as Animatable from 'react-native-animatable';
import {useToasts} from 'react-toast-notifications' 
// Siirrä statukseen

var readyToSend = false;

export default function Write(props) {
    const [oldPost, setOldPost] = useState({});
    const [title, setTitle] = useState("");
    const [visible, setVisible] = useState(false);
    const [diary, setDiary] = useState(false);

    const bodyDispatcher = useRef();;
    const [body, setBody] = useState();

    const { addToast } = useToasts();
    const id = useParams().id;
    function liftDispatcher(l) {
        bodyDispatcher.current = l;
    }
	useEffect(()=> {
        if (id && bodyDispatcher.current)
		    getPost()
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id])
    
    function getPost() {
        server.Get(getUrl()).then(({data}) => {
            setOldPost(data)
            bodyDispatcher.current({
                type: "replace",
                text: data.body,
                createTimeEntry: data.date && data.time + 600 * 1000 < Date.now()});
            setTitle(data.title);
            setVisible(data.visible);
            setDiary(data.date)
        });
    }

    function getUrl() {
        let url = diary || props.diary ? "diary" : "posts";
        return url + (id? "/"+id : "");
    }

    const [status, setStatus] = useState("");
 
    const titleChanged = (event) => {
        setTitle(event.target.value)
    }
    const diaryChanged = (event) => {
        setDiary(event.target.checked);
        if (event.target.checked && !body) {
            createTimeEntry();
        }
    }
    const visibilityChanged = (event) => {
        setVisible(event.target.checked);
    }

    function handlePaste(e) {

        if (e.clipboardData.files.length) {
            e.preventDefault();
            const file = e.clipboardData.files[0];
            const clipboard = e.clipboardData.items;
            if (clipboard.length === 2 && clipboard[1].kind === "file") {
                clipboard[0].getAsString(name => {
                    console.log(name)
                    name = name.match(/src="(.*?)"/)[1] ?? name
                    console.log(name)
                    name = name.match(/([^/]+)$/gm)[0]
                    uploadImage(file, name);
                });
            }
            else
                uploadImage(file);
        }
    }

    const onDrop = (files) => {
        if (files)
            uploadImage(files[0], files[0].name)
    }

    function uploadImage(imagedata, filename) {
        server.UploadImage(imagedata, filename)
            .then(saved_filename => {
                insertToBody("\nimg:"+saved_filename+"\n")
                addToast("File uploaded: "+saved_filename, {appearance: "success"})
            })
            .catch(error => addToast(error.message, {appearance: "error"}));
    }

    const preparePost = () => {
        if (diary) {
            // For diary entries wait for timestamp update, not good
            readyToSend = true;
            bodyDispatcher.current({type: "updateTimestamp"});
        }
        else
            sendPost();       
    }

    function insertToBody(string) {
        bodyDispatcher.current({type: "insert", text: string});
    }

    useEffect(() => {
        if (!body)
            return;
        
        const wordCount = body.split(" ").length;
        const edit_mark = (body === oldPost.body? "" : "*");
        setStatus(wordCount + " sanaa." + edit_mark);
        window.document.title =  `${edit_mark} ${title || diary} - Projektiloki `;

        if (readyToSend)
            sendPost();
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [body, diary, oldPost.body]);

    const sendPost = () => {
        readyToSend = false;
        const data = { body, title, visible }

        server.SendPost(getUrl(), data, oldPost)
            .then(res => handleResponse(res))
            .catch(error => addToast(error.message, {appearance: "error"}));
        
        const handleResponse = (res) => {
            if (res.status >= 200) {
                addToast("Tallennettu "+ moment().format('HH:mm:ss'),
                    {appearance: 'success'});
                if (id)
                    getPost()
                else
                    window.location.pathname = getUrl() + "/" + res.data.id;
            }
        }
    }
    function createTimeEntry() {
        bodyDispatcher.current({type: "updateTimestamp", createTimeEntry: true});
    }

    function keyPress(event) {
        if (event.ctrlKey && event.key === "Enter")
            preparePost()
        if (event.ctrlKey && event.key === "m")
            createTimeEntry();
        if (event.ctrlKey && event.key === ",")
            bodyDispatcher.current({type: "updateTimestamp"});
    }
    function getTitle() {
        if (diary)
            return moment(oldPost.date).format("D. MMMM YYYY, dddd")
        else
            return id? "Edit #"+id : "New post"
    }
	return (
		<div className="App" onKeyUp={keyPress} onPasteCapture={handlePaste}>
                <div className="topbar">
                    <a className="toolbarElement" href={"/edit/"+id}>Kirjaus</a>
                    <a className="toolbarElement" href={"/notes/"+id}>Kategoriat</a>
                </div>
                <h1>{getTitle()}</h1>
                { !diary?
                    <p><input type="text" value={title} onChange={titleChanged} /></p> : null}
                <EditArea
                    liftValue={value => setBody(value)}
                    liftDispatcher={liftDispatcher}/>
                <div>{status}</div>

                <div className="toolbar">
                    <br />
                    <ReactDropzone onDrop={onDrop}>
                        {({getRootProps, getInputProps}) => (
                            <div className="filedrop" {...getRootProps()}>
                                <input {...getInputProps()} />
                                Lataa kuva
                            </div>
                        )}
                    </ReactDropzone>
                    <div>
                        <input type="checkbox" checked={visible} onChange={visibilityChanged} /> 
                        Julkaise <br />
                        <input type="checkbox" disabled={oldPost.created}
                            checked={diary} onChange={diaryChanged} />
                        Aikakirjaus
                    </div>
                    <div><button onClick={createTimeEntry}>Uusi kirjaus</button></div>
                    <div><button onClick={preparePost}>Tallenna</button></div>
                </div>
            {id && oldPost.body &&
                <Message showHidden={true} message={oldPost} />
            }
		</div>
	);
}
