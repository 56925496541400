
import React from 'react';
import { useParams } from "react-router-dom";
import './App.css';

export default function Login() {
	const phrase = useParams().phrase;

	localStorage.setItem("key", phrase)
	
	return (
		<div className="App">
			<h1>Login</h1>
			<p>Setting phrase to {localStorage.getItem("key")}</p>
		</div>
	);
}

