
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import './App.css';
import server from './ServerAPI'
import moment from 'moment'
//import * as Animatable from 'react-native-animatable';

export default function Entries() {
    const id = useParams().method+"/"+useParams().id;
    console.log(id)
    const [oldMessage, setOldMessage] = useState([]);

	useEffect(()=> {
        function getMessage() {
            server.Get(id).then(res => {
                setOldMessage(res.data)
            });
        }
		getMessage()
	}, [id])
    //<img src={logo} className="App-logo" alt="logo" />
    
    function parseDate(time) {
        return moment(time).format('YYYY-MM-DD h:mm:ss')
    }

    const [status, setStatus] = useState("");
    function deleteItem() {
        server.Delete(id)
            .then(() => setStatus("Poistettu."))
            .catch(res => setStatus(res.message))
    }

	return (
		<div className="App">
            <h1>{id? "Delete #"+id : "No ID"}</h1>
            {id &&
            <div>
                <h2>#{oldMessage.id} {oldMessage.title}</h2>
                <p>{parseDate(oldMessage.time)}</p>
                <p>{oldMessage.body}</p>
                <p>
                    <button onClick={deleteItem}>Poista</button> &nbsp;
                    {status}
                </p>
            </div>
            }
		</div>
	);
}

