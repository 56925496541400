import React, { useEffect, useRef, useReducer } from 'react';
import moment from 'moment'

//https://bugzilla.mozilla.org/show_bug.cgi?id=1220696
//textbox.current.focus()
//textbox.current.select()
//console.log(document.execCommand("insertText", false, string))

var caret_pos = -1;
var scroll_pos = -1;
var time_s = 0;
setInterval(() => time_s += 1, 1000)

function bodyReducer(state, action) {
    function insertText(text, start_i = state.textbox.selectionStart, end_i = state.textbox.selectionEnd) {
        state.text = (
            state.text.substr(0, start_i)
            + text
            + state.text.substr(end_i));
    }
    function updateTimestamp() {
        const index = state.text
            .substring(0, state.textbox.selectionStart -1)
            .search(/^t:(?!.*^t:)/sm);
        
        const indexMid = state.text.indexOf(":", index + 4)
        const indexEnd = state.text.indexOf("\n", indexMid)
        
        if (!(-1 < index && index < indexMid && indexMid < indexEnd))
            return
        var oldtime = Number(state.text.substring(indexMid+1, indexEnd));
        oldtime = Number.isNaN(oldtime)? 0 : oldtime;
        const string = state.text.substring(index, indexMid)+ ":"+(oldtime + time_s);
        insertText(string, index, indexEnd)
        caret_pos += string.length - (indexEnd - index);
        time_s = 0;
    }
    function getTimestampString() {
        return (state.text? "\n" : "") + "t:"+moment().valueOf()+":\n";
    }
    if (action.index) {
        state.textbox.selectionStart = action.index;
        state.textbox.selectionEnd = action.index;
    }

    if (state.textbox) {
        caret_pos = state.textbox.selectionEnd
    }
    switch (action.type) {
        case 'replace':
            state.text = action.text;
            break;
        case 'insert':
            updateTimestamp();
            caret_pos = state.textbox.selectionEnd + action.text.length;
            insertText(action.text);
            break
        case 'setTextBox':
            state.textbox = action.textbox;
            break
        case 'updateTimestamp':
            updateTimestamp();
            break;
        default:
            throw new Error();
    }
    if (action.createTimeEntry) {
        let text = getTimestampString();
        insertText(text, state.text.length, state.text.length)
        caret_pos = state.text.length + text.length + 11;
    }

    return {...state};
}

export default function EditArea({liftValue, liftDispatcher}) {
    const textbox = useRef();
    const [bodyState, dispatchBody] = useReducer(bodyReducer, {text:""});

    const textChanged = (event) => {
        dispatchBody({type: "replace", text: event.target.value});
    };

    useEffect(() => {
        liftDispatcher(dispatchBody)
        dispatchBody({type: "setTextBox", textbox: textbox.current});
    }, [])


    useEffect(() => {
        if (caret_pos > -1) {
            textbox.current.selectionStart = caret_pos;
            textbox.current.selectionEnd = caret_pos;
            caret_pos = -1;
        }

        if (scroll_pos > -1) {
            textbox.current.scrollTop = scroll_pos;
            scroll_pos = -1;
        }
        textbox.current.focus();

        liftValue(bodyState.text);
    }, [bodyState])

	return (
		<textarea ref={textbox} value={bodyState.text} onChange={textChanged} />
	)
}