import React from 'react';
import moment from 'moment'
import {server_url} from "./config";
import ModalImage from 'react-modal-image';

moment.relativeTimeThreshold('ss', 6);
moment.relativeTimeThreshold('m', 60);

const DayPlan = ({data}) => {
    function getStyle(time) {
        var now = (new Date).getHours();
        var active, phase;
        if (time < 12)
            phase = "morning";
        else if (time < 18)
            phase = "day";
        else if (time < 24)
            phase = "night";

        active = time < now ? "inactive" : "";

        if (time >= now && time < now + 2)
            active = "red";

        return `dayEntry ${phase} ${active}`;
    }
    const entries = data.split(",")
//            <div className="red"><div style={{marginTop: "50%"}}>-></div></div>
    if (entries.length < 5)
        return <span className="red">Vajaa määritys: {entries.length}/5</span>
    return (
        <div className="dayPlan">
            <div>
            <p className={getStyle(8)}>8: Aamutoimet!</p>
            <p className={getStyle(10)}>10:{entries[0]}</p>
            <p className={getStyle(12)}>12: Ruokailu!</p>
            <p className={getStyle(14)}>14:{entries[1]}</p>
            <p className={getStyle(16)}>16: PP: {entries[2]}</p>
            <p className={getStyle(18)}>18: Peli/Luova/Liikunta: {entries[3]}</p>
            <p className={getStyle(20)}>20: YA: {entries[4]}</p>
            <p className={getStyle(22)}>22: Iltapuuhat</p>
            </div>
        </div>
    )
}
export default function parseBody(body) {
    let formatted_lines = []
    let bodyArray = body.split(/\n/);

    bodyArray.forEach((line, i) => {
        let sub = line.trim().split(":");
        if(sub[0] === "img") {
            const img_url = server_url + '/'+sub[1];
            formatted_lines.push(
                <ModalImage key={i}
                    className={sub[2] ? "" : "photo"}
                    alt={bodyArray[i+1]? bodyArray[i+1].replace(/[&*]/g,"") : ""}
                    large={img_url}
                    small={img_url} />)
        }
        else if(sub[0] === "s") {
            formatted_lines.push(<DayPlan data={sub[1]} />)
        }
        else if(sub[0] === "t") {
            var since = ""
            if (sub[2]) {
                since = (sub[2]?
                    moment(Number(sub[1])).from(Number(sub[1]) + (Number(sub[2]))*1000) : "")
                since = "("+since.substring(0, since.length - 7) + ")"
            }
            formatted_lines.push(
                <div className="time-entry" key={i}>
                    <p className="date">
                        {moment(Number(sub[1])).format("HH:mm") + " " + since}</p>
                </div>)
        }
        else if(sub[0].charAt(0) === "#")
            formatted_lines.push(<h3 key={i}>{line.substring(1)}</h3>)
        else if(sub[0].charAt(0) === "-")
            formatted_lines.push(
                <li key={i}
                    dangerouslySetInnerHTML={{__html: inlineParser(line.substring(1))}}>
                </li>)
        else if(sub[0].charAt(0) === "/")
            void(0)
        else {
            formatted_lines.push(
                <p key={i}
                    dangerouslySetInnerHTML={{__html: inlineParser(line)}}></p>)
        }
    })

    return formatted_lines
    
}

function inlineParser(line) {
    // Text decorations
    const markdowns = [["**", "<b>", "</b>"],
                       ["_", "<u>", "</u>"],
                       ["&&", "<p class='center'>", "</p>"],
                       ["~~", "<s>", "</s>"],
                       ["@", '<a class="category" href="/', "</a>", true],
                       ["https://", '<a target="_blank" href="', "</a>", true],
                       ["http://", '<a target="_blank" href="', "</a>", true],
                       ["*", "<i>", "</i>"]];
    const words = []

    line.trim().split(" ").forEach(word => {
        for (const [symbol, tag_start, tag_end, url = false] of markdowns ) {

            if(word.substring(0, symbol.length) === symbol) {
                if (url)
                    word =  tag_start + word + '">'+word + tag_end
                else
                    word = tag_start + word.substring(symbol.length, word.length)
            }
            if(word.substring(word.length - symbol.length, word.length) === symbol)
                word = word.substring(0, word.length - symbol.length) + tag_end
        }
        words.push(word);
    })
    line = words.join(" ")
    
    //URL Parser
    var i = 0
    while (i++ < 50) {
        const start_i = line.indexOf("[");
        const middle_i = line.indexOf("](", start_i);
        const end_i = line.indexOf(")", middle_i);
        
        if (!(-1 < start_i && start_i < middle_i && middle_i < end_i))
            break;
        
        const desc = line.substring(start_i + 1, middle_i)
        var url = line.substring(middle_i+2, end_i);
        const str_start = line.substring(0, start_i);
        const str_end = line.substring(end_i + 1);

        if (url.substring(0, 4) !== "http" && url.charAt(0) !== "/")
            url = "https://" + url
        if (url.substring(0, 4) === "http")
            url += " target=_blank"
        line = str_start +`<a href=${url}>${desc}</a>` +str_end
    }
    return line
}

//image on editor
//new post -> reroute url to correct