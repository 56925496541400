import React, { useState, useEffect } from 'react';
import moment from 'moment'
import 'moment/locale/fi'
import './App.css';
import parseBody from './Parser';

export default function Message(props) {
	
	const [message, updateMessage] = useState(props.message)

	useEffect(()=> {
		if (props.message.body) {
			props.message.parsedBody = parseBody(props.message.body);
			updateMessage({...props.message});
		}
	}, [props.message])

	function messageStyle() {
		let styles = "message"
		if (props.collapsed)
			styles += " collapsed"
		
		if (!message.visible)
			styles += " private"
		
		if (message.date)
			styles += " diarypost"
		return styles
	}

	return (
		<div className="post" key={message.id} id={message.date? "diary" : "post"+message.id}>
			<h2><a className="posttitle"
				href={(localStorage.getItem("key") ? "/edit" : "")+"/"+message.id}>{message.title}</a></h2>
			<div className="datebox">
			<span className='date'>{moment(message.created).format('YYYY-MM-DD dddd')} </span>
			
			</div>
			<div className={messageStyle()} onClickCapture={props.onClick}>
				{message.parsedBody}
			</div>
		</div>
	);
	//<span className='date'>(päivitetty: {moment(message.time).fromNow()})</span>
}