import React from 'react';
import { Switch, Route, BrowserRouter } from "react-router-dom";
//import logo from './logo.svg';
import './App.css';
import Write from './Write.js';
import Delete from './Delete'
import Login from './Login'
import MainPage from './MainPage'

import {ToastProvider} from 'react-toast-notifications'

function App(props) {
	return (
		<div><ToastProvider autoDismiss="true" autoDismissTimeout="4000">
		<header className="App-header">
			<a className='title' href="/">sake.kapsi.fi</a>
		</header>
		<BrowserRouter>
			<Switch>
				<Route path="*/login/:phrase">
					<Login />
				</Route>
				<Route path="*/delete/:method/:id">
					<Delete />
				</Route>
				<Route path="*/(day|diary)/:id?">
					<Write diary="true" />
				</Route>
				<Route path="*/edit/:id?">
					<Write />
				</Route>
				<Route path="*/:id">
					<MainPage />
				</Route>
				<Route path="/">
					<MainPage />
				</Route>
			</Switch>
		</BrowserRouter></ToastProvider>
		</div>
	);
}

export default App;
